const INPUT_RULES = {
    required: [(v) => !!v || ""],
    email: [
        (v) => !!v || "",
        (v) => /.+@.+\..+/.test(v) || "错误的电子邮箱",
    ],
    phone: [
        (v) => !!v || "",
        (v) => (v && v.length == 11) || "错误的号码",
    ],
    grade: [
        (v) => (v <= 100 && v >= 60) || "",
    ],
    number: [
        (v) => !!v || "",
        (v) => (Number.isInteger(v) && v > 0) || "请输入正整数/Integer required",
    ],
    nullable_number: [
        (v) => (!v ? true : Number.isInteger(v) && v > 0) || "请输入正整数/Integer required",
    ],
    id_number: [
        (v) => !!v || "",
        (v) => (v && /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(v)) || "无效身份证号/Invalid id number",
    ],
}

const CAMP_INFO = {
    basic: {
        name_cn: "姓名（中文）",
        first_name: "英文名/First name",
        last_name: "英文名/Last name",
        gender: "性别/Gender",
        birthday: "出生日期/Date of Birth",
        id_number: "身份证号码/National ID No.",
    },
    contact: {
        phone: "手机/Mobile",
        email: "邮箱/Email",
        addr_district: "通讯地（中文）(省/自治区/直辖市)",
        addr_detail: "通讯地（中文）(详细地址)",
        addr_en: "Address（英文）",
    },
    school: {
        university_cn: "大学（中文）",
        university_en: "University（英文）",
        department_cn: "院系（中文）",
        department_en: "School or Department（英文）",
        is_special_plan: "是否为强基计划/is Strengthening Basic Disciplines Plan",
        class_cn: "班级（中文）",
        class_en: "Class（英文）",
        student_number: "学号/Student ID Number",
        year_of_enrollment: "入学年月/Year of Enrollment",
        year_of_graduation: "预期毕业年月/Expected Year of Graduation",
    },
    degree: {
        major_cn: "主学位（中文）",
        major_en: "Major（英文）",
        double_degree_cn: "双学位（中文）",
        double_degree_en: "Double Degree（英文）",
        second_degree_cn: "二学位（中文）",
        second_degree_en: "Second Degree（英文）",
        minor_degree_cn: "辅修学位（中文）",
        minor_degree_en: "Minor（英文）",
    },
    ranking: {
        overall_gpa_ranking: "全部课程加权学分绩排名/Overall GPA ranking",
        major_gpa_ranking: "必修+专业限选课加权学分绩排名/GPA ranking for required and major elective courses",
        major_students: "专业总人数/Number of students in your major",
        special_notes: "特殊情况说明/Special notes"
    },
    curriculum: {
        core_curriculum: "核心课程（物理、数学和天文） Core Curriculum(physics, math, and astronomy)",
    },
    personal: {
        personal_statement: "个人简述/Personal statement (须英文填写，可附中文翻译)",
    },
    recommend: {
        recommander_a_name: "推荐人1姓名/Name of recommender A",
        recommander_a_phone: "推荐人1手机号/Phone number of recommender A",
        recommander_a_email: "推荐人1邮件地址（官方邮箱）/ Email address of recommender A",
        recommander_a_letter: "推荐人1推荐信/ Letter of recommender A",
        recommander_b_name: "推荐人2姓名/Name of recommender B",
        recommander_b_phone: "推荐人2手机号/Phone number of recommender B",
        recommander_b_email: "推荐人2邮件地址（官方邮箱）/ Email address of recommender B",
        recommander_b_letter: "推荐人2推荐信/ Letter of recommender B",
        recommander_c_name: "推荐人3姓名/Name of recommender C",
        recommander_c_phone: "推荐人3手机号/Phone number of recommender C",
        recommander_c_email: "推荐人3邮件地址（官方邮箱）/ Email address of recommender C",
        recommander_c_letter: "推荐人3推荐信/ Letter of recommender C",
    },
    other: {
        awards: "所获奖励/Awards and Scholarship",
        research: "科研经验（何时何地参加过哪些科研训练，包括方向、题目、导师等）/Research experience",
        publication: "发表的论文、专利、出版物或原创性工作/Publication or manuscript in preparation, patents, etc. 请附上链接（若有）",
    },
    extra: {
        access: "您获得夏令营信息的渠道/How do you know the summer camp",
    }
}

const FILE_TYPES = {
    doc: "application/pdf",
    image: "image/png,image/jpeg,image/gif,image/bmp",
}

const CAMP_FILES = {
    id_photo: {
        desc: "彩色证件照(一寸照片)/ID Photo(jpg 、gif or png)",
        short: "彩色证件照",
        types: FILE_TYPES.image,
    },
    resume: {
        desc: "上传简历/Resume（PDF）",
        short: "简历",
        types: FILE_TYPES.doc,
    },
    transcript: {
        desc: "上传成绩单/Official Transcript（PDF 、jpg 、gif、png or bmp）",
        short: "成绩单",
        types: FILE_TYPES.image + "," + FILE_TYPES.doc,
    },
    ranking_certificate: {
        desc: "上传排名证明（需由班主任签字或有更正式的认可）/Certificate of your ranking（PDF 、jpg 、gif、png or bmp）",
        short: "排名证明",
        types: FILE_TYPES.image + "," + FILE_TYPES.doc,
    },
    english_exams: {
        desc: "上传英语水平证明（四六级、托福、雅思等）/ Official report of English exams",
        short: "英语水平证明",
        types: FILE_TYPES.image + "," + FILE_TYPES.doc,
    },
    other_docs: {
        desc: "其它材料（如：获奖证书，发表或未发表的的学术论文全文、出版物全文、专利等）/Other supporting documents（certificate of awards, full articles of publication or manuscript in preparation, patents, etc.)",
        short: "其它材料",
        types: FILE_TYPES.image + "," + FILE_TYPES.doc,
    },
}


const CURRICULUM_NAMES = {
    name_cn: "课程名（中文）",
    name_en: "Course Title（英文）",
    credit: "学分/Credit",
    grade: "分数或绩点/Grade",
    remark: "说明/Remark（选填）",
}

const CURRICULUM_HEADERS = [
    {
        text: CURRICULUM_NAMES.name_cn,
        align: "start",
        sortable: false,
        value: "name_cn",
    },
    {
        text: CURRICULUM_NAMES.name_en,
        sortable: false,
        value: "name_en",
    },
    {
        text: CURRICULUM_NAMES.credit,
        sortable: false,
        value: "credit",
    },
    {
        text: CURRICULUM_NAMES.grade,
        sortable: false,
        value: "grade",
    },
    {
        text: CURRICULUM_NAMES.remark,
        sortable: false,
        value: "remark",
    },
    {
        text: "操作/Actions",
        sortable: false,
        value: "actions",
    },
]

const GRADE_HEADERS = [
    {
        text: "评委/Professor",
        align: "start",
        value: "professor",
    },
    {
        text: "评分/Grade",
        sortable: false,
        value: "score",
    },
    {
        text: "评分时间/Time",
        value: "time",
    },
]



export default {
    INPUT_RULES: INPUT_RULES,
    CAMP_INFO: CAMP_INFO,
    CAMP_FILES: CAMP_FILES,
    FILE_TYPES: FILE_TYPES,
    CURRICULUM_NAMES: CURRICULUM_NAMES,
    CURRICULUM_HEADERS: CURRICULUM_HEADERS,
    GRADE_HEADERS: GRADE_HEADERS,
};
