<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-if="!scheduleConfig">
      <v-col>
        <v-card>
          <v-card-title>选择面谈模式/Select Interview Mode</v-card-title>
          <v-card-subtitle
            >此选择不可更改，请谨慎选择/This is an irreversible choice</v-card-subtitle
          >
          <v-card-text>
            <v-select
              v-model="scheduleMode"
              :items="modeSelect"
              item-text="desc"
              item-value="name"
            ></v-select>
            <v-card-actions class="justify-center">
              <v-btn
                type="submit"
                :disabled="!scheduleMode"
                color="primary"
                @click="handleSelectMode()"
              >
                确认
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-else>
      <v-col cols="12">
        <v-alert type="info" dense>
          面谈模式/Interview Mode：{{
            scheduleConfig.one_to_many ? "一对多/One To Many" : "一对一/One On One"
          }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-card color="#385F73" dark v-if="scheduleConfig.post">
          <v-card-title class="headline">当前海报</v-card-title>
          <v-list-item>
            <v-list-item-title>文件名/File Name</v-list-item-title>
            <v-list-item-subtitle v-text="scheduleConfig.post.name"></v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>文件大小/File Size</v-list-item-title>
            <v-list-item-subtitle
              v-text="scheduleConfig.post.size + ' bytes'"
            ></v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>上传时间/Upload Time</v-list-item-title>
            <v-list-item-subtitle v-text="scheduleConfig.post.time"></v-list-item-subtitle>
          </v-list-item>
        </v-card>

        <v-card>
          <v-card-title
            class="headline"
            v-text="scheduleConfig.post ? '更新宣传海报/Update Post' : '上传宣传海报/Upload Post'"
          ></v-card-title>

          <v-card-text>
            <v-alert color="info" dense text>
              <div class="text-caption">图片或 PDF， 海报尺寸 宽 90cm x 高 120cm</div>
              <v-divider class="my-2 info" style="opacity: 0.22"></v-divider>
              <div class="text-caption">PDF or picture, with the size of 90cm*120cm</div>
            </v-alert>

            <v-file-input
              ref="fileupload"
              @change="selectFile"
              chips
              show-size
              counter
              :disabled="isUploading"
              :accept="uploadTypes"
            ></v-file-input>
          </v-card-text>
          <v-card-actions v-if="isUploading" class="justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-card-actions>
          <v-card-actions v-else class="justify-center">
            <v-btn color="primary" :disabled="!uploadFile" @click="uploadSubmit">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="scheduleHeaders"
            :items="schedules"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title> 当前面谈安排/Current Schedule </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-dialog persistent v-model="addScheduleDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Add </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">添加面谈安排/New Schedule</span>
                    </v-card-title>

                    <v-card-text>
                      <v-text-field
                        v-model="customLocation"
                        prepend-icon="mdi-map-marker"
                        label="自定义地址/Custom Location"
                        hint="可以填写腾讯会议地址/Input URL for Tencent Meeting"
                        persistent-hint
                        single-line
                      ></v-text-field>

                      <v-data-table
                        :headers="presetHeaders"
                        :items="availablePresets"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon v-if="newSchedulePresets.includes(item.id)"> mdi-check </v-icon>
                          <v-icon @click="newSchedulePresets.push(item.id)" v-else>
                            mdi-plus
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="scheduleAddClose"> Cancel </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="scheduleAddConfirm"
                        :disabled="newSchedulePresets.length == 0"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog persistent v-model="deleteScheduleDialog" max-width="640px">
                  <v-card>
                    <v-card-title class="headline">删除面谈安排/Delete Schedule</v-card-title>
                    <v-card-subtitle>
                      {{ deleteSchedule.desc }} - {{ deleteSchedule.location }}
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="scheduleDeleteClose">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="scheduleDeleteConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>

            <template v-slot:[`item.location`]="{ item }">
              <span v-if="item.location.startsWith('https://meeting.tencent.com/')">
                <v-btn icon :href="item.location" target="_blank">
                  <v-icon>mdi-laptop-account</v-icon>
                </v-btn>
              </span>
              <span v-else> {{ item.location }} </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="handleDeleteSchedule(item)"> mdi-delete </v-icon>
            </template>

            <template v-slot:[`item.time_start`]="{ item }">
              {{ toLocalTime(item.preset.time_start) }}
            </template>
            <template v-slot:[`item.time_end`]="{ item }">
              {{ toLocalTime(item.preset.time_end) }}
            </template>
            <template v-slot:[`item.attendees`]="{ item }">
              <v-chip
                small
                v-for="student in item.attendees"
                :key="student.name"
                :to="`/review/detail/${student.camp_id}`"
                target="_blank"
              >
                {{ student.desc }}</v-chip
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import consts from "@/consts";
import requests from "@/http";

export default {
  data: () => ({
    alerts: null,
    search: "",
    customLocation: "",
    rules: consts.INPUT_RULES,
    scheduleConfig: null,
    scheduleMode: "",
    modeSelect: [
      { name: "one_on_one", desc: "一对一/One On One" },
      { name: "one_to_many", desc: "一对多/One To Many" },
    ],
    availablePresets: [],
    schedules: [],
    scheduleHeaders: [
      { text: "时间/Time", value: "desc" },
      { text: "地点/Location", value: "location" },
      { text: "申请人/Attendees", value: "attendees" },
      {
        text: "操作/Actions",
        sortable: false,
        value: "actions",
      },
    ],
    presetHeaders: [
      { text: "时间/Time", value: "desc" },
      { text: "地点/Location", value: "location" },
      {
        text: "操作/Actions",
        sortable: false,
        value: "actions",
      },
    ],
    addScheduleDialog: false,
    newSchedulePresets: [],
    newScheduleLocation: "",
    newScheduleAttendance: 1,
    deleteSchedule: {
      preset: { id: null, time_start: null, time_end: null, desc: "", location: "" },
      desc: "",
      location: "",
      attendees: [],
    },
    emptySchedule: {
      preset: { id: null, time_start: null, time_end: null, desc: "", location: "" },
      desc: "",
      location: "",
      attendees: [],
    },
    deleteScheduleDialog: false,
    isUploading: false,
    uploadFile: null,
    uploadTypes: consts.FILE_TYPES.image + "," + consts.FILE_TYPES.doc,
  }),
  created() {
    this.getScheduleInfo();
  },
  methods: {
    toLocalTime(time) {
      return new Date(time + "Z").toLocaleString("en-GB", {
        hour12: false,
      });
    },
    selectFile(file) {
      this.uploadFile = file;
    },
    getScheduleInfo() {
      requests
        .get(`/api/review/schedule`)
        .then((res) => {
          this.schedules = res.data.schedules;
          this.scheduleConfig = res.data.config;
          this.availablePresets = res.data.presets;
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },

    handleSelectMode() {
      requests.post(`/api/review/schedule/config`, { mode: this.scheduleMode }).then(() => {
        this.getScheduleInfo();
      });
    },

    uploadSubmit() {
      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      requests
        .post(`/api/review/schedule/post/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.isUploading = false;
          this.uploadFile = null;
          this.$refs.fileupload.reset();
          this.scheduleConfig.post = res.data.file;
          alert(res.data.message);
        })
        .catch((err) => {
          this.isUploading = false;
          alert(err.response.data.detail);
        });
    },

    scheduleAddClose() {
      this.$nextTick(() => {
        this.newSchedulePresets = [];
      });
      this.addScheduleDialog = false;
    },
    scheduleAddConfirm() {
      requests
        .post(`/api/review/schedule/add`, {
          preset_ids: this.newSchedulePresets,
          location: this.customLocation,
        })
        .then(() => {
          this.getScheduleInfo();
        })
        .catch((err) => {
          alert(JSON.stringify(err.response.data.detail));
        });
      this.scheduleAddClose();
    },
    handleDeleteSchedule(item) {
      this.deleteSchedule = item;
      this.deleteScheduleDialog = true;
    },
    scheduleDeleteClose() {
      this.$nextTick(() => {
        this.deleteSchedule = Object.assign({}, this.emptySchedule);
      });
      this.deleteScheduleDialog = false;
    },
    scheduleDeleteConfirm() {
      requests
        .post(`/api/review/schedule/${this.deleteSchedule.id}/delete`, {})
        .then(() => {
          this.getScheduleInfo();
        })
        .catch((err) => {
          alert(JSON.stringify(err.response.data.detail));
        });
      this.scheduleDeleteClose();
    },
  },
};
</script>
